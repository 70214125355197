import request from '@/http'

const prefix = '/api/blade-user'

export const getUserInfo = () => {
	return request({
		url: prefix + '/info',
		method: 'get',
	})
}

export const updatePassword = (oldPassword, newPassword, newPassword1) => {
	return request({
		url: prefix + '/update-password',
		method: 'post',
		data: {
			oldPassword,
			newPassword,
			newPassword1,
		},
	})
}

export const updateInfo = row => {
	return request({
		url: prefix + '/update-info',
		method: 'post',
		data: row,
	})
}
